import { Box, Heading, HStack, Text, VStack } from "@efishery/onefish";
import { useMemo } from "react";
import { PiTicket } from "react-icons/pi";
import BGShape from "src/assets/images/bg_shape.svg";
import { ENV } from "src/configs/env";
import { BLACK } from "src/constants/theme";
import { convertListHTMLToListArray } from "src/utils";

import { DEFAULT_TNC } from "../../constant";
import { IReferralSetting } from "../../types";

interface TermsAndConditionsProps {
  setting: IReferralSetting;
}

export const TermsAndConditions = ({ setting }: TermsAndConditionsProps) => {
  const termsAndConditions = useMemo(() => {
    const dynamicTNC = convertListHTMLToListArray({
      html: setting?.terms_and_conditions,
      outputHTML: true,
    })?.map((text) => ({
      Icon: PiTicket,
      description: text as string,
    }));

    return [...DEFAULT_TNC, ...dynamicTNC];
  }, [setting?.terms_and_conditions]);

  return (
    <>
      <Heading size="md">Cara & Ketentuannya</Heading>
      <VStack
        bgImage={`${ENV.APP_HOST}${BGShape}`}
        backgroundPosition="bottom right"
        backgroundSize="auto"
        backgroundRepeat="no-repeat"
        spacing="1rem"
        width="full"
        border="solid"
        borderColor={BLACK[100]}
        borderWidth="1.5px"
        padding="1rem"
        backgroundColor="white"
        borderRadius="8px"
        height="auto"
        alignItems="flex-start"
        // TODO: Need to fix
        // Short term solution because the border style doens't appear on efisheryku
        // But it works on local
        style={{
          border: `1px solid ${BLACK[100]}`,
          padding: "1rem",
          borderRadius: "8px",
        }}
      >
        {termsAndConditions.map(({ Icon, description }, i) => (
          <HStack key={i}>
            <Box>
              <Icon size={i === 2 ? 27 : 24} color={BLACK[200]} />
            </Box>
            <Box>
              <Text
                data-testid={`txt_tnc-${i}`}
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </Box>
          </HStack>
        ))}
      </VStack>
    </>
  );
};

import { ArrowUpIcon } from "@chakra-ui/icons";
import { Button, Image, Text, VStack } from "@efishery/onefish";
import { useEffect, useState } from "react";
import icWave from "src/assets/images/ic_wave.svg";
import { ENV } from "src/configs/env";

import { GuideReferralProps } from "../..";
import { contentsGuide } from "../../constant";

export function GuideReferral({ handleOnContinue }: GuideReferralProps) {
  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 50) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <>
      <VStack
        alignItems="end"
        position="fixed"
        left="1/3"
        top="0"
        maxWidth="480px"
        width="100%"
        bgColor="white"
        justifyContent="flex-end"
        fontSize="1.125rem"
        zIndex="999999"
      >
        <Button
          variant="link"
          textDecoration="transparent"
          shadow="0"
          display="flex"
          alignItems="center"
          fontSize="1.125rem"
          height="56px"
          padding="10px 16px"
          color="#008080"
          justifyContent="flex-end"
          cursor="pointer"
          onClick={handleOnContinue}
        >
          Lewati
        </Button>
      </VStack>
      <VStack
        backgroundColor="white"
        padding="56px 1.25rem 0 1.25rem"
        alignItems="center"
      >
        {contentsGuide.map((content, i) => (
          <VStack key={i}>
            <Image
              src={`${ENV.APP_HOST}${content.image}`}
              data-testid={`img_referral-content-guide-${i}`}
            />
            <Text
              fontWeight="600"
              fontSize="1.5rem"
              marginTop="1.5rem"
              marginBottom="1.5rem"
              textAlign="center"
              color="#333333"
            >
              {content.title}
            </Text>
            {content.descs.map((desc, j) => (
              <Text
                key={j}
                textAlign="center"
                fontSize="0.875rem"
                color="#333333"
              >
                {desc}
              </Text>
            ))}
          </VStack>
        ))}
      </VStack>
      <VStack backgroundColor="white" width="full">
        <Image src={icWave} width="full" marginTop="4rem" />
      </VStack>
      {isVisible && (
        <VStack
          alignItems="center"
          onClick={scrollToTop}
          padding="0 20px"
          position="fixed"
          bottom="20px"
          zIndex={3}
          maxWidth="480px"
          width="100%"
        >
          <Button
            size="md"
            width="100%"
            rightIcon={<ArrowUpIcon />}
            bgColor="white"
            variant="outline"
          >
            Kembali ke atas
          </Button>
        </VStack>
      )}
    </>
  );
}

export const convertListHTMLToListArray = ({
  html,
  outputHTML,
}: {
  html: string;
  outputHTML?: boolean;
}) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  const listItems = doc.querySelectorAll("li");
  const listItemsText = doc.querySelectorAll("p");
  const listArray = [] as any[];

  (listItems.length > 0 ? listItems : listItemsText)?.forEach((item) => {
    const selectedItem = outputHTML ? item?.innerHTML : item?.textContent;
    const text = selectedItem?.trim();
    if (text) listArray.push(text);
  });

  return listArray;
};

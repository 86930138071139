import { ButtonProps, StackProps } from "@efishery/onefish";
import { ReactChild } from "react";
import { MetaQuery } from "src/libs/dataProvider/types";

import { IMobileFooterProps } from "../../../components/Footer/type";
import { IMobileHeaderProps } from "../../../components/Header/type";
import { IMobileLayoutProps } from "../../../components/Layout/type";

export enum ReferralShareChannelEnum {
  Email = "email",
  Whatsapp = "whatsapp",
  Facebook = "facebook",
  Twitter = "twitter",
  CopyLink = "copy_link",
}

export interface IReferralProps {
  children?: ReactChild;
  chakra?: IReferralChakraProps;
  resourceProps?: {
    gtmID?: string;
    meta?: MetaQuery;
  };
  buttonProps?: {
    lable?: string;
    chakra?: {
      container?: StackProps;
      button?: ButtonProps;
    };
  };
}

export interface IReferralChakraProps {
  container?: StackProps;
}
export interface IReferralPageProps {
  children?: ReactChild;
  headerProps?: IMobileHeaderProps;
  footerProps?: IMobileFooterProps;
  buttonProps?: IReferralProps["buttonProps"];
  resourceProps?: {
    gtmID?: string;
    meta?: MetaQuery;
  };
  chakra?: {
    layout?: IMobileLayoutProps["chakra"];
    referralPage?: IReferralProps["chakra"];
  };
  useOuterContainer: boolean;
}

export interface IReferral {
  code: string;
  count_members: number;
  members: IReferralMembers[];
}

interface IReferralMembers {
  code: string;
  created_at: string;
  id: string;
  lead_id: string;
  activity?: string;
}

export interface IReferralProfile {
  co_referral: string;
  code: string;
  lead_id: string;
  referral_members: { lead_id: string }[];
  type: "juragan" | "reguler";
}

export interface KeyVisual {
  id: string;
  title: string;
  description?: string;
  image_url?: string;
}

export interface IReferralSetting {
  created_at: string;
  created_by: string;
  id: string;
  key_visuals: KeyVisual[];
  terms_and_conditions: string;
  type: string;
  updated_at: string;
  updated_by: string;
}

export interface IReferralProfileResponse {
  data: IReferralProfile;
  message: string;
  success: boolean;
}

export interface IReferralSettingResponse {
  data: {
    data: IReferralSetting;
  };
  message: string;
  success: boolean;
}

export interface GuideReferralProps {
  handleOnContinue: () => void;
}

export interface IReferralMembersActivityCardProps {
  referralData: IReferralMembers;
}

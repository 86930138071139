export const AssetFooter = () => (
  <div style={{ backgroundColor: "white" }}>
    <svg
      width="100%"
      height="98"
      viewBox="0 0 360 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_54972_173671)">
        <rect width="360" height="98" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-54 52.8888L-30.5417 43.537C-7.08334 34.1852 39.8333 15.4815 86.75 17.3519C133.667 19.2222 180.583 41.6667 227.5 52.8889C274.417 64.1111 321.333 64.1111 368.25 54.7593C415.167 45.4075 462.083 26.7038 485.542 17.3519L509 8.0001L509 109L485.542 109C462.083 109 415.167 109 368.25 109C321.333 109 274.417 109 227.5 109C180.583 109 133.667 109 86.75 109C39.8333 109 -7.08335 109 -30.5417 109L-54 109L-54 52.8888Z"
          fill="url(#paint0_linear_54972_173671)"
          fillOpacity="0.7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M385 55.8304L356.246 66.1197C327.921 76.4089 270.413 96.9875 213.333 84.1259C156.254 71.2643 98.7458 24.9626 41.6667 12.101C-15.4125 -0.760537 -72.9208 19.818 -101.246 30.1073L-130 40.3966L-130 133L-101.246 133C-72.9208 133 -15.4125 133 41.6667 133C98.7458 133 156.254 133 213.333 133C270.413 133 327.921 133 356.246 133L385 133L385 55.8304Z"
          fill="url(#paint1_linear_54972_173671)"
          fillOpacity="0.7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-7.15867e-06 48.9428L23.2267 57.7504C46.1067 66.558 92.56 84.1733 138.667 73.1638C184.773 62.1543 231.227 22.52 277.333 11.5105C323.44 0.500977 369.893 18.1162 392.773 26.9238L416 35.7315L416 115L392.773 115C369.893 115 323.44 115 277.333 115C231.227 115 184.773 115 138.667 115C92.56 115 46.1067 115 23.2267 115L-1.87085e-05 115L-7.15867e-06 48.9428Z"
          fill="url(#paint2_linear_54972_173671)"
          fillOpacity="0.7"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_54972_173671"
          x1="46.3046"
          y1="2.17308"
          x2="46.3046"
          y2="109"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B7E9D9" />
          <stop offset="1" stopColor="#038767" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_54972_173671"
          x1="293.247"
          y1="0.788453"
          x2="293.247"
          y2="133"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B7E9D9" />
          <stop offset="1" stopColor="#038767" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_54972_173671"
          x1="74.115"
          y1="1.82692"
          x2="74.115"
          y2="115"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B7E9D9" />
          <stop offset="1" stopColor="#038767" />
        </linearGradient>
        <clipPath id="clip0_54972_173671">
          <rect width="360" height="98" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
);

import { HStack, Text, VStack } from "@efishery/onefish";
import dayjs from "dayjs";
import { PiCurrencyCircleDollar } from "react-icons/pi";
import { AQUA, BLACK } from "src/constants/theme";

import { IReferralMembersActivityCardProps } from "../..";

export function ReferralMembersActivityCard({
  referralData,
}: IReferralMembersActivityCardProps) {
  const referralType = referralData.activity || "registration";
  return (
    <HStack alignItems="start">
      <div
        style={{
          backgroundColor: AQUA[100],
          borderRadius: "0.25rem",
          padding: "0.25rem",
        }}
      >
        <PiCurrencyCircleDollar size="27" color={AQUA[500]} />
      </div>
      {referralType === "registration" && (
        <VStack alignItems="start">
          <Text size="0.875rem" fontWeight="400" color={BLACK[400]}>
            Kamu dapat keuntungan dari petani yang daftar eFisheryKu!
          </Text>
          <Text size="0.875rem" fontWeight="400" color={BLACK[200]}>
            {`${dayjs
              .tz(referralData.created_at, "Asia/Jakarta")
              .locale("id")
              .format("dddd, DD MMMM YYYY")}`}
          </Text>
        </VStack>
      )}
      {referralType === "kabayan" && (
        <VStack alignItems="start">
          <Text size="0.875rem" fontWeight="400" color={BLACK[400]}>
            Kamu dapat keuntungan dari petani yang daftar Kabayan!
          </Text>
          <Text size="0.875rem" fontWeight="400" color={BLACK[200]}>
            {`${dayjs
              .tz(referralData.created_at, "Asia/Jakarta")
              .locale("id")
              .format("dddd, DD MMMM YYYY")}`}
          </Text>
        </VStack>
      )}
      {referralType === "1st_transaction" && (
        <VStack alignItems="start">
          <Text size="0.875rem" fontWeight="400" color={BLACK[400]}>
            Kamu dapat keuntungan dari petani yang melakukan transaksi
            pertamanya!
          </Text>
          <Text size="0.875rem" fontWeight="400" color={BLACK[200]}>
            {`${dayjs
              .tz(referralData.created_at, "Asia/Jakarta")
              .locale("id")
              .format("dddd, DD MMMM YYYY")}`}
          </Text>
        </VStack>
      )}
    </HStack>
  );
}

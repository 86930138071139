import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";

import { isReferralVisited } from "../constant";

export const useGuideOnboarding = () => {
  const [isGuideVisited, setIsGuideVisited] = useState(false);

  const [cookies, setCookie] = useCookies([isReferralVisited]);

  const checkGuideVisit = () => {
    const isVisited = cookies.isReferralVisited;
    if (isVisited) setIsGuideVisited(true);
  };

  const getMonthFromNow = (month: number) => {
    const today = new Date();
    today.setMonth(today.getMonth() + month);

    return today;
  };

  useEffect(() => {
    checkGuideVisit();
  }, [isGuideVisited]);

  const handleOnContinue = () => {
    setIsGuideVisited(true);
    setCookie(isReferralVisited, true, {
      path: "/",
      expires: getMonthFromNow(24),
    });
    window.scrollTo(0, 0);
  };

  return {
    isGuideVisited,
    handleOnContinue,
  };
};

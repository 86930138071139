import {
  AspectRatio,
  Box,
  Button,
  Heading,
  HStack,
  Image,
  Text,
  VStack,
} from "@efishery/onefish";
import { PiUsersFill } from "react-icons/pi";
import { Loading } from "src/components";
import { AQUA, BLACK } from "src/constants/theme";

import {
  ActivityReferral,
  AssetFooter,
  GuideReferral,
  IReferral,
  IReferralProps,
  SharedReferral,
  TermsAndConditions,
} from "../..";
import { REFERRAL_SETTING_KV_ID } from "../../constant";
import { useGuideOnboarding, useReferralData } from "../../hooks";

export function ReferralComponent({ chakra, resourceProps }: IReferralProps) {
  const {
    authToken,
    keyVisuals,
    referralDetail,
    referralDetailLoading,
    setting,
    settingLoading,
  } = useReferralData(resourceProps?.meta);

  const { isGuideVisited, handleOnContinue } = useGuideOnboarding();

  const handleClickBanner = () => {
    window.open("https://efi.sh/programreferral", "_blank");
  };

  const isLoading = referralDetailLoading || settingLoading;

  const footerImgURL = keyVisuals?.[REFERRAL_SETTING_KV_ID.Juragan]?.image_url;

  if (!isGuideVisited)
    return <GuideReferral handleOnContinue={handleOnContinue} />;

  return (
    <>
      <VStack
        width="full"
        boxShadow="sm"
        minHeight="100vh"
        spacing="0"
        position="relative"
        alignItems="stretch"
        {...chakra?.container}
      >
        <VStack
          width="full"
          padding="0"
          spacing="0"
          boxShadow="md"
          bg="white"
          transition=".5s all ease"
          zIndex={isLoading ? -1 : 0}
        >
          <VStack position="relative" w="full" backgroundColor="white">
            <AspectRatio ratio={1 / 1} width="full" backgroundColor="white">
              <Image
                src={keyVisuals?.[REFERRAL_SETTING_KV_ID.Marketing]?.image_url}
                backgroundColor="white"
                width="full"
                objectFit="cover"
                data-testid="img_promoku-detail-header-image"
                alt={`banner-${keyVisuals?.[REFERRAL_SETTING_KV_ID.Marketing]?.title}`}
              />
            </AspectRatio>
          </VStack>
        </VStack>
        <VStack
          alignItems="flex-start"
          width="full"
          spacing="20px"
          padding="14px"
          height="full"
          flexGrow="1"
          boxShadow="md"
          bg="white"
          transition=".5s all ease"
        >
          <SharedReferral
            code={referralDetail?.code || ""}
            authToken={authToken}
            shareBanner={keyVisuals?.[REFERRAL_SETTING_KV_ID.Share]?.image_url}
            shareBannerAlt={`share-banner-${keyVisuals?.[REFERRAL_SETTING_KV_ID.Share]?.title}`}
          />
          <TermsAndConditions setting={setting} />
          <Heading size="md">Petani Hasil Rekomendasimu</Heading>
          <VStack spacing="0.5rem" width="full">
            <VStack
              width="full"
              border="solid"
              borderColor={BLACK[100]}
              borderWidth="1px"
              padding="1rem"
              backgroundColor="white"
              borderRadius="8px"
              height="auto"
              alignItems="flex-start"
            >
              <HStack>
                <Box
                  style={{
                    backgroundColor: AQUA[100],
                    borderRadius: "0.25rem",
                    padding: "0.25rem",
                  }}
                >
                  <PiUsersFill size="27" color={AQUA[500]} />
                </Box>
                <Text>
                  {referralDetail?.count_members || 0} Petani Sudah Pakai Kode
                  Rekomendasimu
                </Text>
              </HStack>
            </VStack>
            <ActivityReferral
              buttonText="Cek Selengkapnya"
              referralUsage={referralDetail?.members as IReferral["members"]}
            />
          </VStack>
        </VStack>
        {footerImgURL && (
          <>
            <VStack
              marginTop="0.5rem"
              alignItems="flex-start"
              width="full"
              spacing="20px"
              padding="14px"
              height="full"
              flexGrow="1"
              boxShadow="md"
              bg="white"
            >
              <Heading size="md">Yang Juga Menarik untuk Kamu Cek!</Heading>
            </VStack>
            <VStack
              width="full"
              padding="0 1rem 1rem 1rem"
              spacing="0"
              bg="white"
              transition=".5s all ease"
              zIndex={isLoading ? -1 : 0}
            >
              <VStack position="relative" w="full" backgroundColor="white">
                <AspectRatio ratio={1 / 1} width="full" backgroundColor="white">
                  <Button variant="link" onClick={handleClickBanner}>
                    <Image
                      src={footerImgURL}
                      backgroundColor="white"
                      width="full"
                      objectFit="cover"
                      data-testid="img_referral_footer"
                      alt={`banner-${keyVisuals?.[REFERRAL_SETTING_KV_ID.Juragan]?.title}`}
                    />
                  </Button>
                </AspectRatio>
              </VStack>
            </VStack>
          </>
        )}
        <AssetFooter />
      </VStack>
      <Loading
        container={{
          position: "absolute",
          height: "full",
          width: "full",
          flexGrow: "1",
          opacity: isLoading ? ".8" : "0",
          transition: ".5s all ease",
          zIndex: isLoading ? "1" : "-1",
        }}
        spinner={{
          size: "xl",
          color: "brand.500",
        }}
      />
    </>
  );
}

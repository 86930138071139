import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Loading } from "src/components";

import Referral from "./Referral";

export default function ReferralPage() {
  const navigate = useNavigate();
  const initialResourceProps = {
    Authorization: "",
    GTM_ID: "",
    "X-Client-Id": "efisheryku",
  };
  const [resourceProps, setResourceProps] = useState(initialResourceProps);

  useEffect(() => {
    const authorizationToken = sessionStorage.getItem("dev_referral_auth");
    const xClientId = sessionStorage.getItem("dev_referral_clientid");
    const gtmId = sessionStorage.getItem("dev_referral_gtmid");
    setResourceProps((prev) => ({
      ...prev,
      ...(authorizationToken && { Authorization: authorizationToken }),
      ...(xClientId && { "X-Client-Id": xClientId }),
      ...(gtmId && { GTM_ID: gtmId }),
    }));
  }, []);

  if (resourceProps.Authorization === "") {
    return <Loading />;
  }

  return (
    <>
      <Referral
        useOuterContainer
        headerProps={{
          transparent: true,
          onPreviousClick() {
            navigate(-1);
          },
        }}
        resourceProps={{
          gtmID: resourceProps.GTM_ID,
          meta: {
            headers: {
              Authorization: resourceProps.Authorization,
              "X-Client-Id": resourceProps["X-Client-Id"],
            },
          },
        }}
      />
    </>
  );
}

import { jwtDecode } from "jwt-decode";
import { useMemo } from "react";
import { SERVICE } from "src/configs/service";
import { useList, useOne } from "src/hooks";
import { BaseKey, MetaQuery } from "src/libs/dataProvider/types";

import {
  IReferral,
  IReferralSetting,
  IReferralSettingResponse,
  KeyVisual,
} from "..";
import { REFERRAL_SETTING_KV_ID } from "../constant";

export const useReferralData = (meta?: MetaQuery) => {
  const authToken = (meta?.headers?.Authorization as string) || "";
  const leadId = (jwtDecode(authToken) as any)?.lead_id;

  // TODO: Perlu direfactor lagi karena ini hit endpoint get one tapi gak lempar params id apapun
  const queryResultsReferralDetail = useList<IReferral>({
    apiUrl: SERVICE.RECOMMENDATION,
    resource: "api/v1/referrals",
    meta: {
      ...meta,
      limit: 30,
    },
  });

  const queryResultsSetting = useOne<IReferralSettingResponse>({
    apiUrl: SERVICE.RECOMMENDATION,
    resource: "api/v1/referrals/settings",
    id: leadId as BaseKey,
    meta,
  });

  const { data: referralDetailResponse, isLoading: referralDetailLoading } =
    queryResultsReferralDetail;
  const { data: settingResponse, isLoading: settingLoading } =
    queryResultsSetting;

  const referralDetail = referralDetailResponse?.data as unknown as IReferral;
  const setting = settingResponse?.data?.data as IReferralSetting;

  const keyVisuals = useMemo(() => {
    const objKV = {
      [REFERRAL_SETTING_KV_ID.Marketing]: {},
      [REFERRAL_SETTING_KV_ID.Share]: {},
      [REFERRAL_SETTING_KV_ID.Juragan]: {},
    } as {
      [x: string]: KeyVisual | undefined;
    };

    Object.keys(objKV).forEach(
      (key) => (objKV[key] = setting?.key_visuals?.find((kv) => kv.id === key))
    );

    return objKV;
  }, [setting]);

  return {
    authToken,
    keyVisuals,
    referralDetail,
    referralDetailLoading,
    setting,
    settingLoading,
  };
};

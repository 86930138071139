import { ReferralShareChannelEnum } from "src/features/Referral";
import { dataLayer } from "src/utils/tracking";

export function trackReferralPageLoaded(authToken: string) {
  const tagManagerProps = {
    dataLayer: {
      event: "Referral Page Loaded",
      eventCategory: "Referral",
      eventLabel: "",
      pageTitle: "Referral",
    },
  };
  dataLayer(tagManagerProps, authToken);
}

export function trackClickShareReferral(authToken: string) {
  const tagManagerProps = {
    dataLayer: {
      event: "click_share_referral",
      eventCategory: "Promo",
      eventLabel: "",
      pageTitle: "Referral",
    },
  };
  dataLayer(tagManagerProps, authToken);
}

export function trackClickShareReferralChannel(
  authToken: string,
  channel: ReferralShareChannelEnum
) {
  const tagManagerProps = {
    dataLayer: {
      event: "click_share_channel_referral",
      eventCategory: "Promo",
      eventLabel: channel,
      pageTitle: "Referral",
    },
  };
  dataLayer(tagManagerProps, authToken);
}

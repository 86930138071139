import { PiHandPointing, PiPaperPlaneTilt, PiUsers } from "react-icons/pi";
import icPilot1 from "src/assets/images/ic_pilot_1.svg";
import icPilot2 from "src/assets/images/ic_pilot_2.svg";
import { ENV } from "src/configs/env";

export const contentsGuide = [
  {
    title: "Apa itu Program Ajak Teman?",
    descs: [
      "Program yang memungkinkan Anda untuk membagikan kode unik ke teman Pembudidaya lainnya.",
      "Anda bisa mendapatkan hadiah setiap kali berhasil mengajak teman daftar akun eFisheryKu dengan memasukkan kode unik Anda.",
    ],
    image: icPilot1,
  },
  {
    title: "Keuntungan Program Ajak Teman",
    descs: [
      "Dengan membagikan kode unik, Anda bisa mendapatkan hadiah berupa voucher atau promo pembelian pakan.",
    ],
    image: icPilot2,
  },
];

export const REFERRAL_SETTING_KV_ID = {
  Marketing: "img_1",
  Share: "img_2",
  Juragan: "img_3",
};

export const DEFAULT_TNC = [
  {
    Icon: PiHandPointing,
    description: "Klik tombol <strong>Bagikan</strong>",
  },
  {
    Icon: PiPaperPlaneTilt,
    description:
      "Kirim kode rekomendasi ke petani lain via WhatsApp/medsos lainnya.",
  },
  {
    Icon: PiUsers,
    description:
      "Pastikan mereka membuat akun eFisheryKu & memasukkan kode rekomendasimu saat mendaftar.",
  },
];

const SHARE_LINK = `${ENV.APP_EFISHERYCOM_WEB}/referral/membership`

export const shareContent = (refCode: string) => {
  const refCodeUppercased = refCode.toUpperCase();
  const link = `${SHARE_LINK}/${refCodeUppercased}`;

  return `Halo, Gabung ke eFisheryKu dan dapatkan keuntungan spesial untuk pembelian pakan. Caranya:

1. Download dan install eFisheryKu lewat link di bawah.
2. Masukkan kode rekomendasi *${refCodeUppercased}* di kolom "Kode Rekomendasi" saat mendaftar.

Klik link berikut untuk gabung sekarang:

${link}
`;
};

export const shareURLFacebook = (refCode: string) => {
  const refCodeUppercased = refCode.toUpperCase();
  return `${SHARE_LINK}/${refCodeUppercased}`;
};

export const isReferralVisited = "isReferralVisited";

import { useEffect } from "react";
import TagManager, { TagManagerArgs } from "react-gtm-module";
import { trackReferralPageLoaded } from "src/features/Referral/pages/Referral/tracker";

import { MobileLayout } from "../../../../components";
import { IReferralPageProps, ReferralComponent } from "../..";

export default function Referral({
  headerProps,
  footerProps,
  chakra,
  resourceProps,
  buttonProps,
  useOuterContainer,
}: IReferralPageProps) {
  if (resourceProps?.gtmID) {
    const tagManagerArgs: TagManagerArgs = {
      gtmId: resourceProps?.gtmID || "",
    };
    TagManager.initialize(tagManagerArgs);
  }

  useEffect(() => {
    if (resourceProps?.gtmID) {
      trackReferralPageLoaded(
        resourceProps?.meta?.headers?.Authorization || ""
      );
    }
  }, [resourceProps?.gtmID]);

  return (
    <MobileLayout
      headerProps={headerProps}
      footerProps={footerProps}
      chakra={chakra?.layout}
      useOuterContainer={useOuterContainer}
    >
      <ReferralComponent
        chakra={chakra?.referralPage}
        resourceProps={resourceProps}
        buttonProps={buttonProps}
      />
    </MobileLayout>
  );
}

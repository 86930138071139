export const OBSERVATORY = {
  50: "#F3F9F7",
  100: "#CDE7E1",
  200: "#9ACFC2",
  300: "#68B7A4",
  400: "#359F85",
  500: "#038767",
  600: "#026C52",
  700: "#02513E",
};

export const AQUA = {
  50: "#F5FFFA",
  100: "#DBF4EC",
  200: "#B7E9D9",
  300: "#94DDC7",
  400: "#70D2B4",
  500: "#4CC7A1",
  600: "#3D9F81",
  700: "#2E7761",
};

export const GREEN = {
  50: "#F3F9F2",
  100: "#D1E7CC",
  200: "#A4D099",
  300: "#76B866",
  400: "#49A133",
  500: "#1B8900",
  600: "#166E00",
  700: "#105200",
};

export const FRESH_GREEN = {
  50: "#FDFEF5",
  100: "#F6FCD7",
  200: "#EDF9AE",
  300: "#E5F686",
  400: "#DCF35D",
  500: "#D3F035",
  600: "#A9C02A",
  700: "#7F9020",
};

export const BLUE = {
  50: "#F6FBFD",
  100: "#DFF4F9",
  200: "#BEE9F4",
  300: "#9EDDEE",
  400: "#7DD2E9",
  500: "#5DC7E3",
  600: "#4A9FB6",
  700: "#387788",
};

export const RED = {
  50: "#FDF5F5",
  100: "#F6D9D9",
  200: "#EEB3B3",
  300: "#E58D8C",
  400: "#DD6766",
  500: "#D44140",
  600: "#AA3433",
  700: "#7F2726",
};

export const ORANGE = {
  50: "#FFF9F5",
  100: "#FDE9D9",
  200: "#FBD4B3",
  300: "#F8BE8E",
  400: "#F49342",
  500: "#C05717",
  600: "#9E430B",
  700: "#763208",
};

export const YELLOW = {
  50: "#FFFDF2",
  100: "#FFF6CC",
  200: "#FFE466",
  300: "#FFED99",
  400: "#FFDB33",
  500: "#FFD200",
  600: "#CCA800",
  700: "#997E00",
};

export const BLACK = {
  50: "#F6F6F6",
  100: "#D2D2D2",
  200: "#A5A5A5",
  300: "#777777",
  400: "#4A4A4A",
  500: "#1D1D1D",
  600: "#171717",
  700: "#111111",
};

export const GREY_BG = "#F8F8F8";
